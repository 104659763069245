import React from "react";
import { LegalSection } from "../sections/Legal/index";
import { Page } from "../components/Page/index";
import { Seo } from "../components/Seo/index";
import { PressSection } from "../sections/Press";

export default function PressPage() {
  return (
    <>
      <Seo title="Press" useTitleTemplate={true} noIndex={true} />
      <Page>
        <PressSection />
      </Page>
    </>
  );
}
