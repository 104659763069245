import React from "react";
import { Section } from "../../components/Section";

export function PressSection(): React.ReactElement {
  return (
    <Section anchor={"press"} heading={"Press"}>
      <p>Download our press kit and get access to our logo files to use in your media!</p>
      <a download={"muzdex_press_kit.zip"} href={"muzdex_press_kit.zip"}>
        <u style={{textDecoration: 'none'}}>📩 Download the press kit</u>
      </a>
    </Section>
  );
}
